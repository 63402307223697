import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export type SpinnerColor = 'primary' | 'current';
export type SpinnerThickness = 'sm' | 'md' | 'lg';
export type SpinnerSize = 'sm' | 'md' | 'lg';

const colors: Record<SpinnerColor, string[]> = {
  primary: ['border-primary-500'],
  current: ['border-current'],
};

const sizes: Record<SpinnerSize, string> = {
  sm: 'size-4',
  md: 'size-8',
  lg: 'size-16',
};

const thicknesses: Record<SpinnerThickness, string> = {
  sm: 'border',
  md: 'border-2',
  lg: 'border-4',
};

const defaultSize: SpinnerSize = 'md';
const defaultColor: SpinnerColor = 'primary';
const defaultThickness: SpinnerThickness = 'md';

@Component({
  standalone: true,
  selector: 'wma-spinner',
  templateUrl: 'spinner.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'hostClasses()',
    '[attr.role]': '"status"',
  },
})
export class SpinnerComponent {
  /** The color of spinner. Sets the color of the border. */
  color = input<SpinnerColor>();
  /** The size of the spinner of spinner. Sets the width and height of the spinner. */
  size = input<SpinnerSize>();
  /** The thickness of the spinner. Sets the border width of the spinner. */
  thickness = input<SpinnerThickness>();

  /* The CSS classname applied to the host element. */
  hostClasses = computed(() => {
    return `inline-flex ${sizes[this.size() || defaultSize]}`;
  });

  /* The list of CSS classes applied to the inner element using NgClass. */
  css = computed(() => {
    return [
      ...colors[this.color() || defaultColor],
      thicknesses[this.thickness() || defaultThickness],
    ];
  });
}
