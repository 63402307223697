<button
  [cdkMenuTriggerFor]="menu"
  type="button"
  id="user-menu-button"
  class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
>
  <span class="sr-only">Open user menu</span>
  <wma-avatar
    [size]="size()"
    [src]="user().picture"
    [initials]="(user().name ?? '').trim()[0]"
    alt=""
  />
</button>

<ng-template #menu>
  <div
    aria-labelledby="user-menu-button"
    @showHideScaleAndOpacity
    class="z-0 mt-2 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    cdkMenu
  >
    <div class="overflow-hidden truncate px-4 py-2 text-sm font-medium text-primary-900">
      {{ user().name }}
    </div>
    <button
      cdkMenuItem
      (click)="emitLogout()"
      class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
    >
      Se déconnecter
    </button>
  </div>
</ng-template>
