import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function getHttpParamsFromQuery(query: Params): HttpParams {
  let params = new HttpParams();

  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (isNumber(value) || (isString(value) && value !== '' && value !== 'null')) {
      params = params.append(key, value.toString());
    }
  });

  return params;
}
