@if (user$ | async; as user) {
  <wma-drawer-container class="flex-1" #drawer>
    <wma-drawer-sidenav>
      <div class="hidden h-full bg-primary-800 lg:flex lg:flex-col">
        <lgs-navigation [links]="links" class="hidden flex-1 overflow-y-auto lg:block" />
        <div
          class="flex h-14 w-full shrink-0 items-center justify-center border-t border-primary-700"
        >
          <wma-profile-dropdown [user]="user" (logout)="logout()" />
        </div>
      </div>
      <div class="lg:hidden">
        @if (drawer.open) {
          <div class="fixed inset-0 z-40 flex" @ngIf>
            <div @showHideOpacity class="absolute inset-0" (click)="drawer.closeSidebar()">
              <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
            <lgs-navigation
              @showHideTranslate
              [links]="links"
              (menuItemClick)="drawer.closeSidebar()"
              class="relative h-full w-full max-w-xs overflow-y-auto bg-primary-800"
            />
          </div>
        }
      </div>
    </wma-drawer-sidenav>
    <wma-drawer-content cdkScrollable>
      <nav class="sticky top-0 z-50 bg-primary-800 lg:hidden print:hidden">
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="flex h-14 items-center justify-between">
            <div class="lg:hidden">
              <button
                (click)="drawer.openSidebar()"
                class="mr-3 inline-flex size-10 items-center justify-center rounded-md bg-primary-800 p-2 text-primary-100 hover:bg-primary-600 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-800"
                aria-label="Open sidebar"
              >
                <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            @if (user) {
              <div class="ml-4 flex w-0 flex-1 items-center justify-end md:ml-6">
                <wma-profile-dropdown [user]="user" (logout)="logout()" />
              </div>
            }
          </div>
        </div>
      </nav>
      <router-outlet />
    </wma-drawer-content>
  </wma-drawer-container>
}
